/**
 * 文件名过滤特殊符 isFilterMode:是否为过滤模式，即为上传附件的时候要过滤所有特殊字符
 */
export function filterFileName(name = '', placeholder = '', isFilterMode = true) {
    let [text, filename = '', suffix = ''] = name.match(/(^.+)(\.[a-z|A-Z|0-9]+$)/);
    let reg = isFilterMode ? /[\s`~\!@#$\^\&\*\(\)\+=\|\{\}':;,\[\]\.\\\-<>\?！￥…（）—【】‘；：”“’。，、？%]/g : /[\s`~\!@#$\^\&\*\(\)\+=\|\{\}':;,\[\]\.\\\-<>\?！￥…（）—【】‘；：”“’。，、？]/g;
    return filename.replace(reg, placeholder) + suffix;
}

/**
 * 将文件名中的特殊符进行url编码
 */
export function encodeFileUrl(url) {
    let [text, preText, fileName] = url.match(/(^.*)(\d{13}_.*$)/) || [];
    return fileName ? preText + filterFileName(fileName, encodeURIComponent, false) : url;
}

/**
 * 图片上传拼接处理
 */
let isEmpty = (str) => {
    if (str === "null" || str === "" || str === 0 || str === "0" || str === null || str === false || typeof str === 'undefined' || str.length === 0) {
        return true;
    }
    return false;
};
export function getImageView(path) {
    if (isEmpty(path)) {
        return ''
    } else if (path.indexOf('http') > -1) {
        return path;
    } else {
        return `https://oss.cycsmeta.com/${path}`;
    }
};
export function delImageView(path) {
    if (isEmpty(path)) {
        return ''
    } else if (path.indexOf('https://cycsmate.oss-cn-shenzhen.aliyuncs.com/') > -1) {
        return path.replace('https://cycsmate.oss-cn-shenzhen.aliyuncs.com/', '');
    } else {
        return path;
    }
};

/**
 * 打开新的页面
 */
export function jumpNewPage(path,query) {
    let routeData = this.$router.resolve({
        path,
        query
    });
    window.open(routeData.href, "_blank");
};

/**
 * 复制一个对象
 */
export function cloneObj(obj) {
    if (typeof obj !== 'object') {
        return {};
    }
    return JSON.parse(JSON.stringify(obj));
}
