<template>
  <el-upload
      :data="dataObj"
      :accept="accept"
      :headers="updheaders"
      class="icon-uploader"
      :action="updUrl"
      :show-file-list="false"
      :on-success="handleUpdSuccess"
      :on-error="handleUpdError"
      :on-progress="handleUpdProgress"
      :before-upload="beforeUpdUpload"
      :multiple="isMultiple"
  >
    <div class="upload-button" :style="{'width': width+'px','height': height+'px'}">
      <button>上传文件</button>
    </div>
  </el-upload>
</template>

<script>
import {encodeFileUrl, getImageView} from '@/utils/tools';
import userApi from "@/api/user.js";

export default {
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    arr: {
      type: Array,
    },
    // 限制文件上传数量
    fileLength: {
      type: Number,
      default: 20
    },
    // 文件类别
    accept: {
      type: String,
      default: '.png,.jpg,.jpeg'
    },
    // 目录编号
    fid: {
      type: String,
      default: '1623943134626025473'
    },
  },
  data() {
    return {
      progress: 100,
      updheaders: {
        'system-auth': this.$store.state.userId,
      },
      limitCount: this.arr.length,
      dataObj: {},
      updUrl: '',
    };
  },
  mounted() {

  },
  computed: {

  },
  methods: {
    isZip(url) {
      return /(\.ZIP)$/i.test(url);
    },
    getPolicyResponse() {
      return userApi.postAction('https://showingsapi.cycsmeta.com/ultra-system/file/policy?fid=' + this.fid);
    },
    setPolicyObj(rawFile, data) {
      this.dataObj.policy = data.policy;
      this.dataObj.signature = data.signature;
      this.dataObj.ossaccessKeyId = data.accessid;
      this.dataObj.key = data.dir + encodeFileUrl(rawFile.name);
      this.dataObj.dir = data.dir;
      this.dataObj.host = data.host;
      this.updUrl = this.dataObj.host;
      if(this.fileLength !== 20) {
        this.limitCount++;
      }
    },
    beforeUpdUpload(rawFile) {
      // 上传图片
      if (this.accept.indexOf('.png') !== -1) {
        if (this.fid === '1630046342280601601') {    // 上传头像图片
          if (rawFile.size / 1024 / 1024 > 1) {
            this.$message.error(rawFile.name + '，大小超过1MB');
            return false;
          }
        } else {    // 上传作品图片
          if (rawFile.size / 1024 / 1024 > 10) {
            this.$message.error(rawFile.name + '，大小超过10MB');
            return false;
          }
        }
      }
      // 上传视频大小不超过500MB
      if (this.accept.indexOf('.mp4') !== -1) {
        if (rawFile.size / 1024 / 1024 > 100) {
          this.$message.error(rawFile.name + '，大小超过100MB');
          return false;
        }
      }
      // 上传zip大小不超过8GB
      if (this.accept.indexOf('.zip') !== -1) {
        if (rawFile.size / 1024 / 1024 / 1024 > 8) {
          this.$message.error(rawFile.name + '，大小超过8GB');
          return false;
        }
      }
      if (this.limitCount >= this.fileLength) {
        this.$message.error('上传数量已达上限，请保存后再上传');
        return false;
      }
      return new Promise( (resolve, reject) => {
        if (this.progress !== 100) {
          let temp = setInterval(() => {
            if (this.progress === 100) {
              clearInterval(temp);
              if (this.limitCount >= this.fileLength) {
                this.$message.error('上传数量已达上限，请保存后再上传');
                reject();
              } else {
                this.progress = 0;
                this.getPolicyResponse().then((res) => {
                  let {code, data, msg} = res.data;
                  if (code === 200) {
                    this.setPolicyObj(rawFile, data);
                    resolve();
                  } else {
                    reject();
                    this.$message.error(msg || '上传失败');
                  }
                }).catch(() => {
                  reject();
                });
              }
            }
          }, 200);
        } else {
          this.progress = 0;
          this.getPolicyResponse().then((res) => {
            let {code, data, msg} = res.data;
            if (code === 200) {
              this.setPolicyObj(rawFile, data);
              resolve();
            } else {
              reject();
              this.$message.error(msg || '上传失败');
            }
          }).catch(() => {
            reject();
          });
        }
      });
    },
    handleUpdProgress(evt) {
      this.progressTxt = (evt.percent || 0).toFixed(2);
      this.$emit('progressChange',this.progressTxt);
    },
    handleUpdSuccess(response, uploadFile) {
      this.progress = 100;
      userApi.postAction('https://showingsapi.cycsmeta.com/ultra-system/file/record', {
        fid: this.fid,
        name: encodeFileUrl(uploadFile.name),
        size: uploadFile.size,
        real: uploadFile.name,
        url: this.dataObj.key,
      }).then((res) => {
        let {code, data, msg} = res.data;
        if (code === 200) {
          this.arr.push({
            fileName: uploadFile.name || '',
            url: getImageView(data),
            size: uploadFile.size
          });
          // 如果上传素材包需要返回文件大小
          if (this.isZip(getImageView(data))) {
            this.$emit('pakSizeChange',uploadFile.size);
          }
          this.$message.success('上传成功：' + (uploadFile.name || ''));
        } else {
          this.$message.error('操作异常：' + (msg || '未知错误'));
        }
      }).catch((err) => {
        this.$message.error('操作异常：' + (err || '未知错误'));
      });
    },
    handleUpdError(error, uploadFile) {
      this.$message.error('上传失败：' + (uploadFile.name || ''));
    },
    handleDelFile(index) {
      this.arr.splice(index, 1);
      this.limitCount--;
      this.$message.success('删除成功');
    }
  },
};
</script>

<style scoped lang="less">
.upload-button button{
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
</style>
